<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex v-if="$route.query.tagID">
          <v-alert :value="true" type="error">
            <v-layout wrap justify-space-between>
              <v-flex class="pa-0">
                <p class="mb-0 mt-2">Productos filtrados con la etiqueta "{{ tag.name }}"</p>
              </v-flex>
              <v-flex class="text-right pa-0" style="    display: flex;justify-content: end;">
                <v-btn flat class="mt-0" :to="{ name: 'products' }">
                  Quitar filtro
                </v-btn>
              </v-flex>
            </v-layout>
          </v-alert>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <app-title title="Productos disponibles en esta tienda">
            <template slot="subtitle">
              Hay
              <span class="font-weight-bold">{{ products.length }}</span>
              productos en total
            </template>
            <template slot="button">
              <div class="btn-all">
                <v-btn
                  v-if="user.shop.admin && user.role.id !== 7"
                  color="back"
                  flat
                  class="mt-0"
                  :to="{ name: 'productAdd' }"
                >
                  Añadir producto
                </v-btn>
                <v-btn
                  v-if="user.store.admin && !user.shop.admin"
                  color="back"
                  flat
                  class="mt-0"
                  :to="{ name: 'productAddStore' }"
                >
                  Añadir producto
                </v-btn>
                <v-menu v-model="menu" :close-on-content-click="false" bottom left min-width="300">
                  <v-btn slot="activator" flat icon class="mt-0">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                  <v-card>
                    <v-card-title class="title">Filtros</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-checkbox v-model="productsFilter.on" label="Activos"></v-checkbox>
                      <v-checkbox v-model="productsFilter.off" label="Inactivos"></v-checkbox>
                      <v-checkbox v-model="productsFilter.hidden" label="Deshabilitados"></v-checkbox>
                      <v-combobox
                        v-model="categories"
                        :items="categoriesAll"
                        item-text="name"
                        item-value="id"
                        label="Categorias"
                        multiple
                        chips
                        clearable
                        small-chips
                      ></v-combobox>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn color="primary" depressed @click="getProducts"> Filtrar </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-toolbar class="elevation-0">
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchText"
                hide-details
                append-icon="search"
                placeholder="Buscar por nombre o codigo"
                flat
                solo
                single-line
                right
                @keyup.native="searchInputHandle"
              ></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="headers"
              :items="products"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td width="100">
                  <router-link
                    style="text-decoration: none; position: relative;"
                    :to="{ name: 'product', params: { id: props.item.id } }"
                  >
                    <v-img
                      :src="props.item.img.url_small"
                      :lazy-src="`https://picsum.photos/10/6`"
                      class="lighten-2"
                      width="100"
                    />
                    <v-chip style="margin: 5px 0" label color="info" text-color="white" small>
                      P{{ props.item.id }}
                    </v-chip>
                    <span
                      v-if="props.item.status_stock"
                      class="ml-1 v-chip v-chip--label theme--light success white--text"
                      style="padding: 4px 10px;border-radius: 20px;position: absolute;left: 0;top: 0;"
                    >
                      {{ props.item.quantity_stock }}
                    </span>
                  </router-link>
                </td>
                <td class="text-xs-left">
                  <v-btn
                    style="margin: 0"
                    color="accent"
                    depressed
                    :title="props.item.name"
                    :to="{ name: 'product', params: { id: props.item.id } }"
                  >
                    <span v-html="shortName(props.item.name)"></span>
                  </v-btn>
                </td>
                <td class="text-xs-left">
                  <template v-if="!props.item.hidden">
                    <v-chip
                      v-if="props.item.status"
                      color="success"
                      text-color="white"
                      label
                      @click="changeStatus(props.item.id, props.item.status, 'estado', props.item)"
                    >
                      <v-icon left>check</v-icon> Activo
                      <span v-if="props.item.status_stock" class="ml-1">
                        ({{ props.item.quantity_stock }} disponibles)</span
                      >
                    </v-chip>
                    <v-chip v-else color="error" text-color="white" label @click="openStatusStock(props.item)">
                      <v-icon left>clear</v-icon> Inactivo
                    </v-chip>
                  </template>
                  <template v-if="props.item.hidden">
                    <v-chip color="grey" text-color="white" label> <v-icon left>clear</v-icon> Deshabilitado </v-chip>
                  </template>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn
      v-if="user.shop.admin && user.role.id !== 7"
      fixed
      dark
      fab
      bottom
      right
      color="accent"
      :to="{ name: 'productAdd' }"
    >
      <v-icon> add </v-icon>
    </v-btn>
    <v-btn
      v-if="user.store.admin && !user.shop.admin"
      fixed
      dark
      fab
      bottom
      right
      color="accent"
      :to="{ name: 'productAddStore' }"
    >
      <v-icon> add </v-icon>
    </v-btn>
    <v-dialog v-model="dialogStatusStock" width="500" persistent>
      <v-card v-if="productActive">
        <TitleDialog :title="`Activar ${productActive.name}`" />
        <v-card-text class="body-1">
          <v-layout wrap>
            <v-flex xs12>
              <v-subheader>¿Como activara este producto?</v-subheader>
              <v-radio-group v-model="stockType" class="ml-3" row>
                <v-radio label="Stock" :value="1"></v-radio>
                <v-radio label="Normal" :value="2"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex v-if="stockType === 1" xs12>
              <v-text-field v-model="stockQuantity" color="secondary" :label="`Cantidad a la venta`" outline>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn flat color="back" @click="dialogStatusStock = false"> volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="changeStatusStock()">
            <span>Activar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import TitleDialog from '../useful/titleDialog.vue'
import appTitle from '../useful/title.vue'
import { GET_API, GET_PRODUCTS_ALL, GET_PRODUCT, GET_TAGS } from '../../config'

export default {
  name: 'Products',
  components: { appTitle, TitleDialog },
  metaInfo: {
    title: 'Productos'
  },
  data: () => ({
    loading: false,
    menu: false,
    tag: {},
    productsAll: [],
    categoriesAll: [],
    products: [],
    categories: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    productsFilter: {
      on: true,
      off: true,
      hidden: false
    },
    searchText: '',
    headers: [
      {
        text: 'Foto',
        align: 'left',
        sortable: false,
        value: 'photo'
      },
      {
        text: 'Nombre',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: false,
        value: 'status'
      }
    ],
    dialogStatusStock: false,
    productActive: {},
    stockType: 2,
    stockQuantity: 1
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    '$route.query': {
      handler: function() {
        this.getProducts()
      },
      deep: true
    }
  },
  mounted() {
    this.getProducts()
    const query = this.$route.query
    if (query.tagID) {
      this.getTag(query.tagID)
    }
  },
  methods: {
    async getTag(id) {
      const res = await this.$http({
        method: 'GET',
        url: GET_TAGS,
        params: { id }
      })
      if (Object.keys(res.data).length > 0) {
        this.tag = res.data[0]
      }
    },
    async getProducts() {
      const query = this.$route.query
      const res = await this.$http({
        method: 'GET',
        url: GET_PRODUCTS_ALL,
        params: { ...this.productsFilter, ...query }
      })
      this.productsAll = res.data
      this.products = res.data
      const filterOrder = []
      this.productsAll.forEach((el, index) => {
        this.productsAll[index].categoriesIds = []
        el.categories.forEach(category => {
          filterOrder.push({
            id: category.id,
            name: category.name
          })
          this.productsAll[index].categoriesIds.push(category.id)
        })
      })
      const categories = this._.uniqBy(filterOrder, 'id')
      this.categoriesAll = this._.orderBy(categories, ['name'], ['asc'])
      if (this.categories.length > 0) {
        const categories = []
        this.categories.forEach(el => {
          categories.push(el.id)
        })
        const products = this._.filter(this.productsAll, item => {
          const int = this._.intersection(item.categoriesIds, categories)
          return int.length > 0
        })
        this.products = products
      }
      this.menu = false
    },
    async changeStatus(id, status, type, item) {
      if (type === 'estado') {
        item.status = !item.status
      }
      if (type === 'quarantine') {
        item.quarantine = !item.quarantine
      }
      await this.$http.put(`${GET_PRODUCT}/${id}/update/${type}`, {
        status: !status
      })
    },
    async changeStatusStock() {
      const { productActive, stockQuantity, stockType } = this
      try {
        await this.$http.put(`${GET_PRODUCT}/${productActive.id}/update-stock`, {
          status: true,
          type: stockType,
          quantity: parseInt(stockQuantity)
        })
        this.dialogStatusStock = false
        this.productActive.status = !this.productActive.status
        this.productActive = {}
        this.stockQuantity = 1
      } catch (error) {
        this.productActive = {}
        this.stockQuantity = 1
      }
    },
    openStatusStock(item) {
      this.dialogStatusStock = true
      this.productActive = item
    },
    shortName(s) {
      const count = 40
      return s.slice(0, count) + (s.length > count ? '...' : '')
    },
    changeProduct(id, status, item) {
      item.status = !item.status
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'changeStatusProduct',
          id,
          status
        }
      }).then(res => {
        console.log(res, key)
      })
    },
    goProduct(route, id) {
      this.$router.push({ name: route, params: { id } })
    },
    searchInputHandle() {
      const search = this.searchText
      let result = this._.filter(this.productsAll, item => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        return res
      })
      if (result.length === 0) {
        result = this._.filter(this.productsAll, item => {
          const res = this._.includes(this._.lowerCase(item.id), this._.lowerCase(search))
          return res
        })
      }
      if (result.length > 0) {
        this.products = result
      } else {
        this.products = []
      }
      this.dialogSearch = false
    }
  }
}
</script>

<style>
.btn-all .v-badge__badge {
  left: -2px;
}
</style>
